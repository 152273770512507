/**
 *
 * _____  _      ______           _____ ______
 * |  __ \| |    |  ____|   /\    / ____|  ____|
 * | |__) | |    | |__     /  \  | (___ | |__
 * |  ___/| |    |  __|   / /\ \  \___ \|  __|
 * | |    | |____| |____ / ____ \ ____) | |____
 * |_|    |______|______/_/   _\_\_____/|______|    _
 * | |                   | | | |   (_)     | (_)   | |
 * | | _____  ___ _ __   | |_| |__  _ ___  | |_ ___| |_
 * | |/ / _ \/ _ \ '_ \  | __| '_ \| / __| | | / __| __|
 * |   <  __/  __/ |_) | | |_| | | | \__ \ | | \__ \ |_
 * |_|\_\___|\___| .__/   \__|_| |_|_|___/ |_|_|___/\__|
 **              | |
 **  _____  ____ |_|___ _______ ______ _____
 * / ____|/ __ \|  __ \__   __|  ____|  __ \
 *| (___ | |  | | |__) | | |  | |__  | |  | |
 * \___ \| |  | |  _  /  | |  |  __| | |  | |
 * ____) | |__| | | \ \  | |  | |____| |__| |
 *|_____/ \____/|_|  \_\ |_|  |______|_____/
 *
 */
export default [
  'ExperienceBundle',
  'HostVerification',
  'PaymentMethods',
  'PreviewBookings',
  'Rating',
  'Subscriptions',
  'SuperHost',
  'TermBookings',
  'Wallet'
]
