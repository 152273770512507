import tagTypes from './tagTypes'
import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react'
import cookie from 'js-cookie'
import {HYDRATE} from 'next-redux-wrapper'

import config from 'config'

export function prepareBaseQuery(path) {
  if (!path.startsWith('http')) {
    path = `${config.API_SERVER_BASE}/api/${path}`
  }

  return fetchBaseQuery({
    baseUrl: path,
    headers: {'content-type': 'application/json'},
    prepareHeaders: (headers, {extra}) => {
      headers.set('x-access-token', (extra && extra.token) || (cookie && cookie.get('token')) || '')
      headers.set('accept-language', 'en')
      headers.set('x-cp-client', 'cp-web-client')

      return headers
    }
  })
}

export const Api = createApi({
  extractRehydrationInfo(action, {reducerPath}) {
    if (action.type === HYDRATE) {
      return action.payload[reducerPath]
    }
  },
  baseQuery: prepareBaseQuery(`${config.API_BASE}`),
  endpoints: () => ({}),
  tagTypes: tagTypes
})
